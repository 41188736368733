import { mdiContentSaveCheck, mdiDoorClosedCancel, mdiWeight } from "@mdi/js"
import Icon from "@mdi/react"
import axios from "axios"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { Alert, Modal, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { getSessionData } from "../../../../utils/storageUtils"
import ModalRomanear from "./modalRomanear"
import ModalConfirmacao from "../../../../components/ModalDeConfirmacao/ModalConfirmacao"
import ModalBalanca from "./modalBalança"
import { speak } from "../../../../utils/notifySons"
import ModalCarregando from "../../../../components/ModalCarregando/ModalCarregando"

interface ModalContagemProps {
    isOpen: boolean
    fecharModal: () => void
    localContagem: string
    contagem: string
    modoCont: string
    ladoCont: string
    idLocalContagem: number
    cnpj: string
    armazem: string
    corredor: string
    titulo: string;
}

const ModalContagem: React.FC<ModalContagemProps> = ({
    isOpen,
    fecharModal,
    localContagem,
    contagem,
    modoCont,
    ladoCont,
    idLocalContagem,
    cnpj,
    armazem,
    corredor,
    titulo
}) => {
    const [showModal, setShowModal] = useState<boolean>(isOpen)
    const [idLocal, setIdLocal] = useState<number | string>('')
    const [wms, setWms] = useState<number | string>('')
    const [UA, setUA] = useState<number | string>('')
    const [vazio, setVazio] = useState<boolean>(false)
    const [UN, setUN] = useState<string>('')
    const [produto, setProduto] = useState<string>('')
    const [qtde, setQtde] = useState<number>(0)
    const [lote, setLote] = useState<number | string>('')
    const [validade, setValidade] = useState<string>('')
    const [messageAlert, setMessageAlert] = useState<string>()
    const [mostraBtnRomanear, setMostraBtnRomanear] = useState<boolean>(true)
    const [tipoPeso, setTipoPeso] = useState<string>('')
    const [localContar, setLocalContar] = useState<string>('')
    const [mostraModalRomanear, setMostraModalRomanear] = useState<boolean>(false)
    const [infosModal, setInfosModal] = useState<any[]>([])
    const [viewLoteValidade, setViewLoteValidade] = useState<boolean>(false)
    const [qtdeJaRomaneado, setQtdeJaRomaneado] = useState<number>(0)
    const [disabled, setDisabled] = useState<boolean>(false)
    const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false)
    const [mostraModalBalanca, setMostraModalBalanca] = useState<boolean>(false)
    const [mostraModalCarregando, setMostraModalCarregando] = useState<boolean>(false)
    const [idLocalASerContado, setIdLocalASerContado] = useState<number>()

    //#region Refs para setar foco
    const idLocalRef = useRef<HTMLInputElement>(null)
    const uaRef = useRef<HTMLInputElement>(null)
    const vazioRef = useRef<HTMLInputElement>(null)
    const produtoRef = useRef<HTMLInputElement>(null)
    const qtdeRef = useRef<HTMLInputElement>(null)
    const loteRef = useRef<HTMLInputElement>(null)
    const validadeRef = useRef<HTMLInputElement>(null)

    const navigate = useNavigate()

    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")
    const usuarioLogado = getSessionData("NomeUsuario")

    async function verificaUA() {
        if (!UA) {
            return
        }

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/UA/${UA}/${cnpj}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setMessageAlert("")
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                setUA("")
                toast.error(erro.response.data.message, {
                    autoClose: 5000
                })
                speak(erro.response.data.message)
                if (erro.response.data.messageAlert) {
                    setMessageAlert(erro.response.data.messageAlert)
                }
            }
        })
    }

    async function verificaProduto() {
        if (!produto) {
            return setMostraBtnRomanear(true)
        } else if (!UA) {
            return toast.info("Insira o número da U.A")
        }

        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/produto/${produto}/${cnpj}/${UA}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setWms(resposta.data.idWms)
            setProduto(resposta.data.codigo)
            setLote(resposta.data.lote ? resposta.data.lote : '')
            setUN(resposta.data.unidade)
            setTipoPeso(resposta.data.tipoPeso)

            if (
                resposta.data.tipoPeso &&
                resposta.data.tipoPeso === 'VARIADO_OPTICO' ||
                resposta.data.tipoPeso === 'VAR_ROM_MANUAL'
            ) {
                setMostraBtnRomanear(false)
            } else {
                setMostraBtnRomanear(true)
            }
        }).catch(function (erro) {
            setMostraBtnRomanear(true)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
                speak(erro.response.data.message)
                setWms("")
                setUN("")
                setProduto("")
                setLote("")
            }
        })
    }

    async function fecharLocal() {
        if (!idLocal) {
            return toast.info("Insira o ID do local para prosseguir")
        }

        axios.put(`${process.env.REACT_APP_API_BASE_URL}/fechar/local?idFilial=${idFilial}`, {
            idLocal,
            contagem,
            modoCont,
            ladoCont,
            armazem,
            corredor,
            cnpj,
            titulo
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            speak(resposta.data.message)
            setIdLocalASerContado(resposta.data.idLocal)
            setLocalContar(resposta.data.endereco)
            setIdLocal("")
            setWms("")
            setUA("")
            setVazio(false)
            setProduto("")
            setQtde(0)
            setLote("")
            setValidade("")
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
                speak(erro.response.data.message)
                if (erro.response.data.message === 'Não há locais para contar' || erro.response.data.message === 'Não há locais para contabilizar') {
                    setInfosModal([])
                    fecharModal()
                }
            }
        })
    }

    async function verificaRomaneio() {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/romaneio/${cnpj}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            gravar()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
                speak(erro.response.data.message)
            }
        })
    }

    async function gravar(pesoEmbTotal?: number) {

        // if (!idLocal) {
        //     return toast.info("Preencha o ID do local para prosseguir")
        // } else if (contagem === '1º Cont') {
        //     if (vazio && ((qtde || produto || validade || !isNaN(Number(lote))) || !UA)) {
        //         return toast.info("Vazio está marcado como verdadeiro")
        //     } else if (!vazio && (!UA || !qtde || !produto || !validade || lote === null || lote === undefined || isNaN(Number(lote)))) {
        //         return toast.info("Preencha todas as informações para prosseguir")
        //     }
        // } else {
        //     if (vazio && ((qtde || produto) || !UA)) {
        //         return toast.info("Vazio está marcado como verdadeiro")
        //     } else if (!vazio && (!UA || !qtde || !produto)) {
        //         return toast.info("Preencha todas as informações para prosseguir")
        //     }
        // }

        if (vazio) {
            if (!idLocal) {
                return toast.info("Informe o local para prosseguir");
            }
        } else if (
            !idLocal ||
            !UA ||
            !qtde ||
            !produto ||
            !validade ||
            lote === null ||
            lote === undefined ||
            isNaN(Number(lote))
        ) {
            return toast.info("Preencha as informações para prosseguir")
        }

        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/gravar/local?idFilial=${idFilial}`, {
            vazio,
            idLocal,
            UA,
            produto,
            qtde,
            lote,
            validade,
            contagem,
            tipoPeso,
            wms,
            usuarioLogado,
            armazem,
            corredor,
            modoCont,
            cnpj,
            titulo,
            pesoEmbTotal
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            speak(resposta.data.message)
            if (!vazio) {
                if (resposta.data.endereco) {
                    setLocalContar(resposta.data.endereco)
                } else if (resposta.data.message === 'Não há mais locais a serem contabilizados') {
                    setInfosModal([])
                    fecharModal()
                }
            } else {
                setInfosModal([])
                fecharLocal()
            }
            setIdLocal("")
            setWms("")
            setUA("")
            setVazio(false)
            setProduto("")
            setQtde(0)
            setLote("")
            setValidade("")
            setDisabled(false)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
                speak(erro.response.data.message)
            }
        }).finally(function () {
            if (mostraModalBalanca) {
                setMostraModalBalanca(false)
            }
        })
    }

    async function confereQtdeRomaneada() {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/romaneado/${cnpj}/${UA}/${produto}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setQtdeJaRomaneado(resposta.data.data)
            if (resposta.data.data > 0) {
                setDisabled(true)
                setMostraBtnRomanear(true)
            } else {
                setDisabled(false)
                setMostraBtnRomanear(false)
            }
        }).catch(function (erro) {
            setDisabled(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
                setQtdeJaRomaneado(0)

            }
        })
    }

    async function deletaRomaneio() {
        setMostraModalCarregando(true)
        axios.delete(`${process.env.REACT_APP_API_BASE_URL}/deleta/romaneio/${cnpj}/${UA}/${produto}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            speak(resposta.data.message)
            setMostraModalConfirm(false)
            setInfosModal([])
            fecharModal()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
                speak(erro.response.data.message)
            }
        }).finally(function () {
            setMostraModalCarregando(false)
        })
    }

    const handleValidadeChange = (e: ChangeEvent<HTMLInputElement>) => {
        // Remove todos os caracteres não numéricos
        let input = e.target.value.replace(/\D/g, '');
        if (input.length > 8) {
            // Limita a entrada a 8 caracteres
            input = input.slice(0, 8);
        }

        if (input.length <= 2) {
            input = input;
        } else if (input.length <= 4) {
            input = `${input.slice(0, 2)}/${input.slice(2)}`;
        } else {
            input = `${input.slice(0, 2)}/${input.slice(2, 4)}/${input.slice(4)}`;
        }

        setValidade(input);
    };

    const handleKeyDown = (event: any, ref: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            ref.current?.focus();
        }
    };

    useEffect(() => {
        setShowModal(isOpen);
    }, [isOpen]);


    return (
        <>
            <Modal
                className="mt-2"
                show={showModal}
                onShow={() => {
                    setIdLocalASerContado(idLocalContagem)
                    if (infosModal.length == 0) {
                        setLocalContar(localContagem)
                        setIdLocal("")
                        setWms("")
                        setUA("")
                        setVazio(false)
                        setUN("")
                        setProduto("")
                        setQtde(0)
                        setLote("")
                        setValidade("")
                        setDisabled(false)
                        setMostraBtnRomanear(true)
                    }

                    if (contagem === '2º Cont' || contagem === 'Recontagem') {
                        setViewLoteValidade(true)
                    } else {
                        setViewLoteValidade(false)
                    }

                }}>
                <Modal.Header className="bg-white" style={{ justifyContent: 'center' }}>
                    <Modal.Title style={{ flex: '1', textAlign: 'center', color: 'black' }}>{"Contagem"}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem' }}
                        onClick={() => {
                            if (qtde && qtde == qtdeJaRomaneado) {
                                setInfosModal([{
                                    localContagem: localContagem,
                                    idLocal: idLocal,
                                    wms: wms,
                                    UA: UA,
                                    vazio: vazio,
                                    UN: UN,
                                    produto: produto,
                                    qtde: qtde,
                                    lote: lote || '',
                                    validade: validade || ''
                                }])

                                setMostraModalConfirm(true)
                                setShowModal(false)
                            } else {
                                setInfosModal([])
                                fecharModal()
                            }
                        }}
                    ></i>
                </Modal.Header>
                <Modal.Body className="bg-white">

                    <Row>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="text"
                                value={localContar}
                                disabled
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                style={{ fontSize: '13px' }}
                            />
                            <label className="form-label">Local a contar</label>
                        </div>
                        <div className="col-6 mt-2">
                            <button
                                type="button"
                                className="w-100 btn btn-novo rounded fs-5"
                                onClick={() => {
                                    fecharLocal()
                                }}
                            >
                                <span className='me-1'>Fechar local</span><Icon path={mdiDoorClosedCancel} size={0.8} />
                            </button>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={idLocal}
                                ref={idLocalRef}
                                autoFocus
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onKeyDown={(event) => handleKeyDown(event, uaRef)}
                                onChange={(event) => setIdLocal(parseInt(event.target.value))}
                                onBlur={() => {
                                    if (idLocal && idLocal !== idLocalASerContado) {
                                        toast.error("Local incorreto")
                                        idLocalRef.current!.focus()
                                    }
                                }}
                            />
                            <label className="form-label">ID do local</label>
                        </div>

                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={wms}
                                disabled
                                className="form-control bordasInferiorInput text-black tamanho-input"
                            />
                            <label className="form-label">WMS</label>
                        </div>
                    </Row>

                    <Row className="mt-2">
                        {messageAlert &&
                            <Alert variant="danger" onClose={() => setMessageAlert('')} dismissible>
                                <Alert.Heading>U.A maior que U.A do estoque</Alert.Heading>
                                <ul>
                                    <li>{messageAlert}</li>
                                </ul>
                            </Alert>
                        }
                    </Row>

                    <Row className="justify-content-between">
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={UA}
                                ref={uaRef}
                                disabled={disabled}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onKeyDown={(event) => handleKeyDown(event, produtoRef)}
                                onChange={(event) => setUA(parseInt(event.target.value))}
                                onBlur={verificaUA}
                            />
                            <label className="form-label">U.A</label>
                        </div>

                        <div className="col-3 mt-2" style={{ marginLeft: '-30px' }}>
                            <div className="form-group ms-2">
                                <label
                                    htmlFor="horizontal"
                                    className="form-label"
                                    style={{
                                        opacity: 0.9,
                                        fontSize: '0.8rem'
                                    }}
                                >
                                    Vazio
                                </label>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="horizontal"
                                        checked={vazio}
                                        ref={vazioRef}
                                        // onKeyDown={(event) => handleKeyDown(event, produtoRef)}
                                        onChange={(e) => {
                                            setVazio(e.target.checked);
                                        }}
                                        style={{
                                            width: '55px'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-3 form-floating mt-1">
                            <input
                                type="text"
                                value={UN}
                                disabled
                                className="form-control bordasInferiorInput text-black tamanho-input"
                            />
                            <label className="form-label">UN</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="text"
                                value={produto}
                                ref={produtoRef}
                                disabled={disabled}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onKeyDown={(event) => handleKeyDown(event, qtdeRef)}
                                onChange={(event) => setProduto(event.target.value)}
                                onBlur={verificaProduto}
                            />
                            <label className="form-label">Produto</label>
                        </div>

                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={qtde}
                                ref={qtdeRef}
                                disabled={disabled}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onKeyDown={(event) => handleKeyDown(event, loteRef)}
                                onChange={(event) => setQtde(parseInt(event.target.value))}
                            />
                            <label className="form-label">Qtde</label>
                        </div>
                    </Row>

                    <Row hidden={viewLoteValidade}>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={lote}
                                ref={loteRef}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onKeyDown={(event) => handleKeyDown(event, validadeRef)}
                                onChange={(event) => setLote(parseInt(event.target.value))}
                            />
                            <label className="form-label">Lote</label>
                        </div>

                        <div className="col-6 form-floating mt-1">
                            <input
                                type="text"
                                value={validade}
                                ref={validadeRef}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={handleValidadeChange}
                            />
                            <label className="form-label">Validade</label>
                        </div>
                    </Row>

                    <Row className="mt-3">
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 btn btn-cancelar rounded fs-5"
                                hidden={mostraBtnRomanear}
                                onClick={() => {
                                    if (!produto || !qtde || !wms) {
                                        return toast.info("Preencha quantidade e código")
                                    } else {
                                        setShowModal(false)
                                        setMostraModalRomanear(true)

                                        setInfosModal([{
                                            localContagem: localContagem,
                                            idLocal: idLocal,
                                            wms: wms,
                                            UA: UA,
                                            vazio: vazio,
                                            UN: UN,
                                            produto: produto,
                                            qtde: qtde,
                                            lote: lote != null ? lote : '',
                                            validade: validade || ''
                                        }])
                                    }
                                }}
                            >
                                <span className='me-1'>Romanear</span><Icon path={mdiWeight} size={0.8} />
                            </button>
                        </div>
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 btn btn-novo rounded fs-5"
                                onClick={() => {
                                    if (!mostraBtnRomanear) {
                                        verificaRomaneio()
                                    } else if (tipoPeso === 'VARIADO_BALANÇA') {
                                        setInfosModal([{
                                            localContagem: localContagem,
                                            idLocal: idLocal,
                                            wms: wms,
                                            UA: UA,
                                            vazio: vazio,
                                            UN: UN,
                                            produto: produto,
                                            qtde: qtde,
                                            lote: lote || '',
                                            validade: validade || ''
                                        }])

                                        setMostraModalBalanca(true)
                                        setShowModal(false)
                                    } else {
                                        gravar()
                                    }
                                }}
                            >
                                <span className='me-1'>Gravar</span><Icon path={mdiContentSaveCheck} size={0.8} />
                            </button>
                        </div>
                    </Row>

                    <Row className="mt-2 border border-primary"></Row>

                    <Row className="mt-2 mb-2">
                        <div className="col-4 form-floating mt-1">
                            <input
                                type="text"
                                value={contagem}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">Contagem</label>
                        </div>

                        <div className="col-4 form-floating mt-1">
                            <input
                                type="text"
                                value={modoCont}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">Modo</label>
                        </div>

                        <div className="col-4 form-floating mt-1">
                            <input
                                type="text"
                                value={ladoCont}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">Lado</label>
                        </div>
                    </Row>

                    <Row className="mb-2">
                        <div className="col-6 form-floating">
                            <input
                                type="text"
                                value={armazem}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">Armazém</label>
                        </div>

                        <div className="col-6 form-floating">
                            <input
                                type="text"
                                value={corredor}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">Corredor</label>
                        </div>
                    </Row>

                </Modal.Body>
            </Modal>

            <ModalRomanear
                isOpen={mostraModalRomanear}
                fecharModal={async () => {
                    setMostraModalRomanear(false)
                    setShowModal(true)

                    setLocalContar(infosModal[0].localContagem)
                    setIdLocal(infosModal[0].idLocal)
                    setWms(infosModal[0].wms)
                    setUA(infosModal[0].UA)
                    setVazio(infosModal[0].vazio)
                    setUN(infosModal[0].UN)
                    setProduto(infosModal[0].produto)
                    setQtde(infosModal[0].qtde)
                    setLote(infosModal[0].lote)
                    setValidade(infosModal[0].validade)

                    await confereQtdeRomaneada()
                }}
                codigo={produto}
                cnpj={cnpj}
                qtde={qtde}
                wms={wms}
                ua={UA}
            />

            <ModalConfirmacao
                mostrar={mostraModalConfirm}
                msgAcao1="Cancelar"
                msgAcao2="Confirmar"
                msgPrincipal="Existe romaneio gravado, caso confirme a saída os registros serão excluidos"
                Acao1={() => {
                    setMostraModalConfirm(false)
                    setShowModal(true)
                }}
                Acao2={deletaRomaneio}
            />

            <ModalBalanca
                isOpen={mostraModalBalanca}
                fecharModal={() => {
                    setMostraModalBalanca(false)
                    setShowModal(true)

                    setLocalContar(infosModal[0].localContagem)
                    setIdLocal(infosModal[0].idLocal)
                    setWms(infosModal[0].wms)
                    setUA(infosModal[0].UA)
                    setVazio(infosModal[0].vazio)
                    setUN(infosModal[0].UN)
                    setProduto(infosModal[0].produto)
                    setQtde(infosModal[0].qtde)
                    setLote(infosModal[0].lote)
                    setValidade(infosModal[0].validade)
                }}
                qtde={qtde}
                acao={gravar}
            />

            <ModalCarregando
                mensagem="Carregando..."
                mostrar={mostraModalCarregando}
            />
        </>
    )
}

export default ModalContagem