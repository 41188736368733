import React, { useEffect, useState } from 'react';
import { Table, Form } from 'react-bootstrap';
import { ITabela } from '../Formularios/Table/TabelaInterface';
import Paginacao from '../Formularios/Pagination';
import { Avatar } from '@mui/material';
import { mdiDownload } from "@mdi/js";

interface ClickableTableProps {
  coluna: ITabela[];
  itemsPerPage: number;
  data: Array<Record<string, any>>;
  onRowClick: (row: Record<string, any>) => void;
  usaRowClick?: boolean;
  paddingHead?: string;
  paddingBody?: string;
  posicaoTexto?: string;

  usaAcoes?: boolean;

  usaEditar?: boolean;
  iconeEditar?: string;
  acaoEditar?: (row: any) => void;
  corIconeEditar?: string;

  usaExcluir?: boolean;
  iconeExcluir?: string;
  acaoExcluir?: (row: any) => void;
  corIconeExcluir?: string;

  usaVisualizar?: boolean;
  iconeVisualizar?: string;
  acaoVisualizar?: (row: any) => void;
  corIconeVisualizar?: string;

  usaBaixar?: boolean;
  iconeBaixar?: string;
  acaoBaixar?: (row: any) => void;
  corIconeBaixar?: string;

  tamanhoIcones?: string;
  tamanhoFontBody?: string;
  tamanhoFontHead?: string;

  labelpesquisa?: string;
  acessopesquisa?: string;

  viewAcoesConfSaida?: boolean
}

const ClickableTable: React.FC<ClickableTableProps> = ({
  coluna,
  itemsPerPage,
  data,
  usaRowClick = false,
  onRowClick,
  paddingHead = '1',
  paddingBody = '1',
  posicaoTexto = 'text-start', // Altere para text-start

  usaAcoes = false,

  usaEditar = false,
  iconeEditar = 'bi bi-pencil-square',
  acaoEditar = function (row: any) { },
  corIconeEditar = 'text-primary',

  usaExcluir = false,
  iconeExcluir = 'bi bi-trash3',
  acaoExcluir = function (row: any) { },
  corIconeExcluir = 'text-danger',

  usaVisualizar = false,
  iconeVisualizar = "bi bi-eye",
  acaoVisualizar = function (row: any) { },
  corIconeVisualizar = "text-dark",

  usaBaixar = false,
  iconeBaixar = "bi bi-download",
  acaoBaixar = function (row: any) { },
  corIconeBaixar = "text-dark",

  tamanhoIcones = "fs-4",
  tamanhoFontBody = '15px',
  tamanhoFontHead = '15px',

  labelpesquisa,
  acessopesquisa = "id",

  viewAcoesConfSaida = true
}) => {
  const [viewAcoesConf, setViewAcoesConf] = useState<any>(true)
  const [viewAcoesPadrao, setViewAcoesPadrao] = useState<any>(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState<any>(data); // Initialize with data
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const itens = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const verificaAvatar = (item: any, acesso: string) => {
    if (acesso === 'avatar') {
      return item[acesso] ? <Avatar src={`data:image/png;base64,${item[acesso]}`} alt='Avatar' /> : <i className="bi bi-buildings fs-3"></i>;
    }
    else if (acesso === "situacao" || acesso === "ativo" || acesso === "bloqueio" || acesso === "emb_controle") {

      return item[acesso] === 1 || item[acesso] === true ? <span className="badge bg-primary">Ativo</span> : <span className="badge bg-danger">Inativo</span>
    }
    else {
      return item[acesso];
    }
  };

  const Pesquisar = (value: string, acesso: string) => {
    if (value === "") {
      setFilteredData(data);
      setCurrentPage(1);
    } else {

      const pesquisados = data.filter(function (info: any) {
        return info[acesso].toString().toLowerCase().includes(value.toLowerCase())
      });
      setFilteredData(pesquisados);
      setCurrentPage(1);
    }
  };

  const [buscar, setBuscar] = useState("");

  useEffect(() => {
    Pesquisar(buscar, acessopesquisa);
  }, [buscar, data]); // Re-run search when `buscar` or `data` changes

  useEffect(() => {
    setFilteredData(data);
    setCurrentPage(1);
  }, [data]); // Update filteredData when `data` changes

  useEffect(() => {
    if (viewAcoesConfSaida == false) {
      setViewAcoesConf(false)
      setViewAcoesPadrao(true)
    }
  }, [viewAcoesConfSaida])

  return (
    <>
      {labelpesquisa && <div className='col-lg-12 col-sm'>
        <div className="form-floating">
          <input
            type="text"
            onChange={(e) => setBuscar(e.target.value)}
            className="form-control bordasInferiorInput text-black"
            aria-describedby="emailHelp"
            placeholder="Procurar por nome de usuários"
          />
          <label>{labelpesquisa}</label>
        </div>
      </div>}
      <div className="table-responsive">
        <Table striped bordered hover>
          <thead className='table-cabecalho'>
            <tr>
              {usaAcoes && (
                <th
                  hidden={viewAcoesConf}
                  style={{ fontSize: tamanhoFontHead }}
                  className={`p-${paddingHead} ${posicaoTexto} text-nowrap bd-highlight`} // text-start
                >
                  Ações
                </th>
              )}
              {coluna.map((item, index) => (
                <th
                  style={{ fontSize: tamanhoFontHead }}
                  className={`p-${paddingHead} ${posicaoTexto} text-nowrap bd-highlight`} // text-start
                  key={index}
                >
                  {item.titulo}
                </th>
              ))}
              {usaAcoes && (
                <th
                  hidden={viewAcoesPadrao}
                  style={{ fontSize: tamanhoFontHead }}
                  className={`p-${paddingHead} ${posicaoTexto} text-nowrap bd-highlight`} // text-start
                >
                  Ações
                </th>
              )}
            </tr>
          </thead>
          <tbody className='overflow-x'>
            {itens.map(function (row: any, index: any) {
              return (
                <tr key={index}>
                  {usaAcoes && (
                    <td hidden={viewAcoesConf} className={`p-${paddingBody} ${posicaoTexto} text-nowrap bd-highlight`}>
                      {usaEditar && (
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={() => acaoEditar(row)}
                          className={`${corIconeEditar} ${iconeEditar} ${tamanhoIcones} me-2`}
                        ></i>
                      )}
                      {usaExcluir && (
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={() => acaoExcluir(row)}
                          className={`${corIconeExcluir} ${iconeExcluir} ${tamanhoIcones} me-2`}
                        ></i>
                      )}
                      {usaVisualizar && (
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={() => acaoVisualizar(row)}
                          className={`${corIconeVisualizar} ${iconeVisualizar} ${tamanhoIcones} me-2`}
                        ></i>
                      )}
                      {usaBaixar && (
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={() => acaoBaixar(row)}
                          className={`${corIconeBaixar} ${iconeBaixar} ${tamanhoIcones} me-2`}
                        ></i>
                      )}
                    </td>
                  )}

                  {coluna.map((col, i) => (
                    <td
                      style={{ textAlign: 'left', fontSize: tamanhoFontBody, cursor: usaRowClick ? "pointer" : "default" }}
                      className={`p-${paddingBody} ${posicaoTexto} text-nowrap bd-highlight`} // text-start
                      onClick={() => onRowClick(row)}
                      key={i}
                    >
                      {verificaAvatar(row, col.acesso)}
                    </td>
                  ))}
                  {usaAcoes && (
                    <td hidden={viewAcoesPadrao} className={`p-${paddingBody} ${posicaoTexto} text-nowrap bd-highlight`}>
                      {usaEditar && (
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={() => acaoEditar(row)}
                          className={`${corIconeEditar} ${iconeEditar} ${tamanhoIcones} me-2`}
                        ></i>
                      )}
                      {usaExcluir && (
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={() => acaoExcluir(row)}
                          className={`${corIconeExcluir} ${iconeExcluir} ${tamanhoIcones} me-2`}
                        ></i>
                      )}
                      {usaVisualizar && (
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={() => acaoVisualizar(row)}
                          className={`${corIconeVisualizar} ${iconeVisualizar} ${tamanhoIcones} me-2`}
                        ></i>
                      )}
                      {usaBaixar && (
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={() => acaoBaixar(row)}
                          className={`${corIconeBaixar} ${iconeBaixar} ${tamanhoIcones} me-2`}
                        ></i>
                      )}
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>

        </Table>
        <Paginacao
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          dados={filteredData}
        />
      </div>
    </>
  );
};

export default ClickableTable;
