import { mdiContentSaveCheck } from "@mdi/js"
import Icon from "@mdi/react"
import axios from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { Modal, Row } from "react-bootstrap"
import { toast } from "react-toastify";
import { getSessionData } from "../../../../utils/storageUtils";
import { useNavigate } from "react-router-dom";
import ModalCarregando from "../../../../components/ModalCarregando/ModalCarregando";
import convertData from "../../../../hooks/convertData";
import ModalConfirmacao from "../../../../components/ModalDeConfirmacao/ModalConfirmacao";
import { speak } from "../../../../utils/notifySons";

interface ModalRomanearProps {
    isOpen: boolean,
    fecharModal: () => void;
    codigo: string;
    cnpj: string;
    qtde: number;
    ua: number | string;
    wms: number | string;
}

const ModalRomanear: React.FC<ModalRomanearProps> = ({
    isOpen,
    fecharModal,
    codigo,
    cnpj,
    qtde,
    ua,
    wms
}) => {

    const [barra, setBarra] = useState<string>()
    const [pb, setPb] = useState<number | string>()
    const [pl, setPl] = useState<number | string>()
    const [validade, setValidade] = useState<string>()
    const [producao, setProducao] = useState<string>()
    const [rg, setRg] = useState<number | string>()
    const [qtdeUa, setQtdeUa] = useState<number | string>()
    const [qtdeJaRomaneado, setQtdeJaRomaneado] = useState<number>(0)
    const [mostraModalCarregar, setMostraModalCarregar] = useState<boolean>(false)
    const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(isOpen)

    const barraRef = useRef<HTMLInputElement>(null)
    const pbRef = useRef<HTMLInputElement>(null)
    const plRef = useRef<HTMLInputElement>(null)
    const validadeRef = useRef<HTMLInputElement>(null)
    const producaoRef = useRef<HTMLInputElement>(null)
    const rgRef = useRef<HTMLInputElement>(null)
    const qtdeUaRef = useRef<HTMLInputElement>(null)

    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")
    const usuarioLogado = getSessionData("NomeUsuario")

    const navigate = useNavigate()

    const handleDataChange = (e: ChangeEvent<HTMLInputElement>, setInput: (e: string) => void) => {
        // Remove todos os caracteres não numéricos
        let input = e.target.value.replace(/\D/g, '');
        if (input.length > 8) {
            // Limita a entrada a 8 caracteres
            input = input.slice(0, 8);
        }

        if (input.length <= 2) {
            input = input;
        } else if (input.length <= 4) {
            input = `${input.slice(0, 2)}/${input.slice(2)}`;
        } else {
            input = `${input.slice(0, 2)}/${input.slice(2, 4)}/${input.slice(4)}`;
        }

        setInput(input);
    };

    async function verificaBarra() {
        if (!barra) {
            return
        }

        setMostraModalCarregar(true)
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/barra/${barra}/${cnpj}/${codigo}/${ua}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setPb(parseFloat(resposta.data.data[0].PB).toFixed(3))
            setPl(parseFloat(resposta.data.data[0].PL).toFixed(3))
            setValidade(resposta.data.data[0].VALIDADE)
            setProducao(resposta.data.data[0].PRODUCAO)
            setRg(resposta.data.data[0].RG)
            setQtdeUa(resposta.data.qtde ? resposta.data.qtde : '')
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
                speak(erro.response.data.message)
                setPb('')
                setPl('')
                setValidade('')
                setProducao('')
                setRg('')
                setQtdeUa('')

                if (status === 404) {
                    toast.info("Preencha todas as informações se deseja gravar uma barra sem cadastro", {
                        autoClose: 5000
                    })
                    speak("Preencha todas as informações se deseja gravar uma barra sem cadastro")
                }
            }
        }).finally(function () {
            setMostraModalCarregar(false)
        })
    }

    async function verificaQtdeRomaneado() {
        setMostraModalCarregar(true)
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/romaneado/${cnpj}/${ua}/${codigo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setQtdeJaRomaneado(resposta.data.data)
            barraRef.current!.focus()
        }).catch(function (erro) {
            setQtdeJaRomaneado(0)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
            }
        }).finally(function () {
            setMostraModalCarregar(false)
        })
    }

    async function gravarRomaneio() {
        if (!barra || !codigo || !pl || !pb || !validade || !producao || !ua || !qtdeUa) {
            return toast.info("Preencha todos os campos para prosseguir")
        } else if (qtde === qtdeJaRomaneado) {
            return toast.info("Quantidade para romaneio já atingida")
        }

        setMostraModalCarregar(true)

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/gravar/romaneio/inventario?idFilial=${idFilial}`, {
            barra,
            codigo,
            pl,
            pb,
            validade: convertData(validade),
            producao: convertData(producao),
            ua,
            rg,
            wms,
            cnpj,
            usuarioLogado
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            speak(resposta.data.message)
            setBarra('')
            setPb('')
            setPl('')
            setValidade('')
            setProducao('')
            setRg('')
            setQtdeUa('')
            barraRef.current!.focus()
            verificaQtdeRomaneado()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                speak(erro.response.data.message)
                toast.error(erro.response.data.message)
            }
        }).finally(function () {
            setMostraModalCarregar(false)
        })
    }

    async function deletaRomaneio() {
        setMostraModalCarregar(true)
        await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/deleta/romaneio/${cnpj}/${ua}/${codigo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            speak(resposta.data.message)
            toast.success(resposta.data.message)
            setMostraModalConfirm(false)
            fecharModal()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                speak(erro.response.data)
                toast.error(erro.response.data.message)
            }
        }).finally(function () {
            setMostraModalCarregar(false)
        })
    }

    const handleKeyDown = (event: any, ref: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            ref.current?.focus();
        }
    };

    useEffect(() => {
        setShowModal(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (qtdeJaRomaneado === qtde && showModal) {
            toast.success("Quantidade para romaneio atingida!", {
                autoClose: 3500
            })
            speak("Quantidade para romaneio atingida!")
            fecharModal()
        }
    }, [qtdeJaRomaneado])

    return (
        <>
            <Modal className="mt-3" show={showModal} onShow={() => {
                setQtdeJaRomaneado(0)
                verificaQtdeRomaneado()
                setBarra('')
                setPb('')
                setPl('')
                setValidade('')
                setProducao('')
                setRg('')
                setQtdeUa('')
            }}>
                <Modal.Header className="bg-white" style={{ justifyContent: 'center' }}>
                    <Modal.Title style={{ flex: '1', textAlign: 'center', color: 'black' }}>{"Romaneio"}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem' }}
                        onClick={() => {
                            if (qtde != qtdeJaRomaneado && qtdeJaRomaneado > 0) {
                                setMostraModalConfirm(true)
                                setShowModal(false)
                            } else {
                                fecharModal()
                            }
                        }}
                    ></i>
                </Modal.Header>

                <Modal.Body className="bg-white">
                    <Row>
                        <div className="form-floating mt-1">
                            <input
                                type="number"
                                value={barra}
                                autoFocus
                                ref={barraRef}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={(e) => setBarra(e.target.value)}
                                onBlur={verificaBarra}
                                onKeyDown={(e) => handleKeyDown(e, pbRef)}
                            />
                            <label className="form-label">Barra</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={codigo}
                                disabled
                                className="form-control bordasInferiorInput text-black tamanho-input"
                            // onChange={(e) => setCodigoBarra(e.target.value)}
                            />
                            <label className="form-label">Código</label>
                        </div>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={wms}
                                disabled
                                className="form-control bordasInferiorInput text-black tamanho-input"
                            />
                            <label className="form-label">WMS</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={pb}
                                ref={pbRef}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={(e) => {
                                    const value = parseFloat(e.target.value).toFixed(3)
                                    setPb(Number(value))
                                }}
                                onKeyDown={(e) => handleKeyDown(e, plRef)}
                            />
                            <label className="form-label">PB</label>
                        </div>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={pl}
                                ref={plRef}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={(e) => {
                                    const value = parseFloat(e.target.value).toFixed(3);
                                    setPl(Number(value));
                                }}
                                onKeyDown={(e) => handleKeyDown(e, validadeRef)}
                            />
                            <label className="form-label">PL</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="text"
                                value={validade}
                                ref={validadeRef}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={(e) => handleDataChange(e, setValidade)}
                                onKeyDown={(e) => handleKeyDown(e, producaoRef)}
                            />
                            <label className="form-label">Validade</label>
                        </div>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="text"
                                value={producao}
                                ref={producaoRef}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={(e) => handleDataChange(e, setProducao)}
                                onKeyDown={(e) => handleKeyDown(e, rgRef)}
                            />
                            <label className="form-label">Produção</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={rg}
                                ref={rgRef}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={(e) => setRg(parseInt(e.target.value))}
                                onKeyDown={(e) => handleKeyDown(e, qtdeUaRef)}
                            />
                            <label className="form-label">RG</label>
                        </div>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={qtdeUa}
                                ref={qtdeUaRef}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={(e) => setQtdeUa(parseInt(e.target.value))}
                            />
                            <label className="form-label">Qtde. U.A</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={ua}
                                disabled
                                className="form-control bordasInferiorInput text-black tamanho-input"
                            />
                            <label className="form-label">U.A</label>
                        </div>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={qtdeJaRomaneado}
                                disabled
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={(e) => setQtdeJaRomaneado(parseInt(e.target.value))}
                            />
                            <label className="form-label">Qtde. Romaneada</label>
                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <Row className="justify-content-end w-100 mb-2">
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 btn btn-novo rounded fs-5"
                                onClick={gravarRomaneio}
                            >
                                <span className='me-1'>Gravar</span><Icon path={mdiContentSaveCheck} size={0.8} />
                            </button>
                        </div>
                    </Row>
                </Modal.Footer>
            </Modal>

            <ModalCarregando
                mostrar={mostraModalCarregar}
                mensagem="Carregando..."
            />

            <ModalConfirmacao
                mostrar={mostraModalConfirm}
                msgAcao1="Cancelar"
                msgAcao2="Confirmar"
                msgPrincipal="O romaneio não está completo, caso confirme a saída os registros serão excluidos"
                Acao1={() => {
                    setMostraModalConfirm(false)
                    setShowModal(true)
                }}
                Acao2={deletaRomaneio}
            />
        </>
    )

}


export default ModalRomanear