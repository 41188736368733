import armazem from "../assets/img/armazem.png";
import avatar from "../assets/img/avatar.png";
import { Form, Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ApiPost from "../api/endPoints/usePost";
import { toast } from "react-toastify";
import ApiGet from "../api/endPoints/useGet";
import ModalLoading from "../components/Formularios/Modal/ModalLoading";
import { getSessionData, setSessionData } from "../utils/storageUtils";
import avatar_padrao from '../assets/img/avatar-default.jpg'
import { Col, Modal, Row } from "react-bootstrap";
import InputSemBorda from "../components/Formularios/Inputs/InputsSemBorda";
import { Button } from "../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiCloseCircle, mdiKeyboardReturn } from "@mdi/js";
import AvatarFilial from "../components/Formularios/AvatarFilial";
import ModalSelecionarFilialLink from "./cadastro/filiais/filiais/components/ModalSelecionaClinicaLink/ModalSelecionaClinicaLink";
import axios from "axios";
import logo from "../assets/img/logo_sm.png"
import { ModalTrocarSenhaLink } from "./administrativo/usuario/components/ModalTrocarSenhaLink"

function NavBar() {
  const token = getSessionData("MultfilialWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const isVinculo = getSessionData("isPerfilVinculo") || "0";
  const navigate = useNavigate();
  const usuarioLogado = getSessionData("NomeUsuario") || "";
  const perfilLogado = getSessionData("PerfilUsuario") || "";
  const id_usuario = getSessionData("DadosUsuarioID") || "";
  const login_usuario = getSessionData("DadosUsuario") || "";
  const avatarfilial = getSessionData("DadosAvatarfilial") || "";

  const [abrir, setAbrir] = useState<boolean>(false);
  const [viewBtnAdm, setViewBtnAdm] = useState<boolean>()

  //Estado para armazenar se o dispositivo é movel ou não
  const [isMobile, setIsMobile] = useState(false)

  //Monitorar a largura da janela e atualizar o estado isMobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // const [linkManual, set_LinkManuel] = useState("")
  // function CarregarLinks() {

  //   axios.get(`${process.env.REACT_APP_API_BASE_URL}/codigosistema/listar`)
  //     .then(function (resposta) {

  //       set_LinkManuel(resposta.data[0].defusr01)
  //     }).catch(function (erro) {

  //       toast.error(erro)
  //     })
  // }


  const [modalAltSenha, setModalAltSenha] = useState<boolean>(false)//controle do modal ''show ''
  function abrirModalAltSenha() {
    setModalAltSenha(true)
  }

  useEffect(() => {
    // CarregarLinks()
    if (usuarioLogado === "") {
      navigate("/login");
    }
  }, []);

  const logoff = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const [avatarImg, setAvatarImg] = useState<string>(avatarfilial || "");
  const [loading, setLoading] = useState<boolean>(false);
  const carregarAvatar = async (id_usuario: string) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/usuario/carregar/:idusuario",
      parametros: {
        idusuario: id_usuario,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((response) => {
        const { avatar } = response[0];
        setLoading(false);
        setAvatarImg(avatar);
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Falha ao carregar dados. Motivo: ${msg}`);
      });
  };
  useEffect(() => {
    if (id_usuario !== "") carregarAvatar(id_usuario);
  }, [id_usuario]);

  //#endregion

  //#region Carregar Filiais do usuario
  const [filiais, setFiliais] = useState<any[]>([]);
  const [avatarImgFilial, setAvatarImgFilial] = useState<string>("");
  const [idfilial, setIdfilial] = useState<string>("");
  const [nomefilial, setNomefilial] = useState<string>("");

  const params = useParams()

  const carregarFiliais = async (id_usuario: string, carregaModal: boolean) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/filial/usuario/listar/:id_usuario",
      parametros: {
        id_usuario: id_usuario,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((response) => {

        const retornoApi = response;
        const qtdfilial = retornoApi.length;
        setLoading(false);
        if (qtdfilial == 1) {
          setFiliais(response);
          setAvatarImgFilial(response[0].avatar);
          setSessionData("imgFilial", response[0].avatar)
          setIdfilial(response[0].id_filial);
          setNomefilial(response[0].fantasia);
          setSessionData("DadosIdfilial", response[0].id_filial);
          setSessionData("DadosNomefilial", response[0].fantasia);
        } else if (qtdfilial > 1) {
          setFiliais(response);
          if (carregaModal) {
            setAbrir(true);
          }

          //setSessionData("DadosIdfilial", response[0].id_filial);
          //setSessionData("DadosNomefilial", response[0].fantasia);

        }
      })
      .catch((erro) => {
        const status = erro.response.status
        if (status == 403 || status == 401) {
          toast.error("Acesso negado, faça seu login novamente")
          navigate("/login")
        } else {
          setLoading(false);
          const msg =
            erro.response?.data.message !== undefined
              ? erro.response?.data.message
              : erro.message;
          toast.error(`Falha ao carregar dados. Motivo: ${msg}`);
        }
      });
  };

  async function buscaFilial() {
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/busca/filial/${params.idFilial}`, {
      headers: {
        Authorization: token
      }
    }).then(function (resposta) {
      setFiliais(resposta.data.data)
      setIdfilial(resposta.data.data[0].id_filial)
      setAvatarImgFilial(resposta.data.data[0].avatar);
      setSessionData("imgFilial", resposta.data.data[0].avatar)
      setNomefilial(resposta.data.data[0].fantasia);
      setSessionData("DadosIdfilial", resposta.data.data[0].id_filial);
      setSessionData("DadosNomefilial", resposta.data.data[0].fantasia);
    }).catch(function (erro) {
      const status = erro.response.status
      if (status == 403 || status == 401) {
        toast.error("Acesso negado, faça seu login novamente")
        navigate("/login")
      } else {
        toast.error(erro.response.data.message)
      }
    })
  }

  useEffect(() => {
    const newIdFilial = params.idFilial

    if (id_usuario !== "" && !(newIdFilial)) {
      carregarFiliais(id_usuario, true);
    } else if (newIdFilial) {
      buscaFilial()
    }
  }, [id_usuario]);

  // useEffect(() => {
  //   if (params.idfilial) {
  //     buscaFilial()
  //   }
  // }, [])

  //#endregion

  const fecharModal = () => {
    sessionStorage.clear();
    navigate("/login");
    // setAbrir(false)
  };

  const receberDados = (dados: any) => {
    setAvatarImgFilial(dados.avatar);
    setSessionData("imgFilial", dados.avatar)
    // setSessionData("isPerfilVinculo", dados.id_empresa)
    setIdfilial(dados.id_filial);
    setNomefilial(dados.fantasia);
    setSessionData("DadosAvatarfilial", dados.avatar);
    setSessionData("DadosIdfilial", dados.id_filial);
    setSessionData("DadosNomefilial", dados.fantasia);
    navigate("/menu/menuPrincipal");
    setAbrir(false);
  };

  //Função para verificar token
  // async function verificaJWT() {
  //   await axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/jwt`, {
  //     headers: {
  //       Authorization: token
  //     }
  //   }).then(function (resposta) {
  //     console.log("deu certo: " + resposta)
  //   }).catch(function (erro) {
  //     const status = erro.response.status
  //     if (status == 403 || status == 401) {
  //       toast.error("Acesso negado, faça seu login novamente")
  //       navigate("/login")
  //     }
  //     // toast.error("Acesso negado, faça seu login novamente")
  //     // navigate("/login")
  //     console.warn(erro)
  //   })
  // }

  // useEffect(() => {
  //   setTimeout(() => {
  //     verificaJWT()
  //   }, 10000)
  // }, [])

  function verificaViewBtnAdm() {
    const idPerfil = getSessionData("id_perfil")
    if (idPerfil == '12') {
      setViewBtnAdm(true)
    } else {
      setViewBtnAdm(false)
    }
  }

  useEffect(() => {
    verificaViewBtnAdm()
  }, [])

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-nova">
        <Link
          to="/menu/menuPrincipal"
          className="border-0 d-flex align-items-center"
          onClick={() => {
            sessionStorage.removeItem("Processo")
            sessionStorage.removeItem("ProcessoEntrada")

            carregarFiliais(id_usuario, false)
          }}
        >
          <img src={logo} alt="Logo" style={{ width: "50px", height: "50px" }} />
        </Link>
        <div className="d-flex ms-auto">
          <div className="dropdown me-2">
            <a
              className="nav-link dropdown-toggle arrow-none mt-2"
              // data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              // id="dropdownMenu2"
              onClick={(e) => {
                e.preventDefault()
                setAbrir(true)
              }}
            >
              <AvatarFilial
                avatarImgFilial={avatarImgFilial}
                alt="filial"
                className="rounded-action-icon imgEmpresa"
              />
              <label className="filial d-none d-md-inline-block" style={{ color: "black" }}>
                &nbsp;{idfilial} - {nomefilial}
              </label>
              <i className="mdi mdi-chevron-down d-none d-sm-inline-block align-middle" />
            </a> 
            {/* <div
              className="dropdown-menu dropdown-menu-animated topbar-dropdown-menu dropdown-menu-scroll dropwdown-menu-sm"
              style={{
                left: "-50%", // Move o dropdown para a direita
                top: "0",     // Garante que ele esteja alinhado ao topo
                transform: "translateX(10px)", // Ajusta mais para a lateral
              }}
            >
              {filiais.map((item) => {
                return (
                  <a
                    className="dropdown-item notify-item"
                    onClick={() => {
                      setAvatarImgFilial(item.avatar);
                      setSessionData("imgFilial", item.avatar)
                      setIdfilial(item.id_filial);
                      setNomefilial(item.fantasia);
                      setSessionData("DadosAvatarfilial", item.avatar);
                      setSessionData("DadosIdfilial", item.id_filial);
                      setSessionData("DadosNomefilial", item.fantasia);

                      if (getSessionData("isPerfilSuper") == "1") {
                        setSessionData("isPerfilVinculo", item.id_empresa)
                      }

                      setLoading(true)

                      setTimeout(() => {
                        navigate("/menu/menuPrincipal");
                        setLoading(false)
                      }, 1000)
                    }}
                  >
                    <img
                      src={
                        item.avatar !== null
                          ? `data:image/png;base64,${item.avatar}`
                          : armazem
                      }
                      alt="filial"
                      className="imgEmpresa"
                    />
                    <label className="form-label">
                      &nbsp; {item.fantasia}
                    </label>
                  </a>
                );
              })}
            </div> */}
          </div>
          <li className="dropdown notification-list list-unstyled topbar-menu float-end mb-0 d-flex">
            <a
              className="nav-link dropdown-toggle nav-user arrow-none me-0"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <span className="account-user-avatar">
                <img
                  src={avatarImg
                    ? `data:image/png;base64,${avatarImg}`
                    : avatar}
                  alt="user"
                  className="rounded-circle" />
              </span>
              <span>
                <span className="account-user-name" style={{ color: 'black' }}>{usuarioLogado}</span>
                <span className="account-position">{perfilLogado}</span>
              </span>
            </a>
            <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown ">
              <div className=" dropdown-header noti-title">
                <h6 className="text-overflow m-0">Bem vindo !</h6>
              </div>
              <ModalTrocarSenhaLink />
              {/* <a style={{ cursor: 'pointer' }} className="dropdown-item notify-item" onClick={abrirModalAltSenha}>
                <i className="mdi mdi-account-key me-1"></i>
                <span>Alterar Senha</span>
              </a> */}
              {/* <a href="" className="dropdown-item notify-item">
            <i className="mdi mdi-lifebuoy me-1"></i>
            <span>Suporte</span>
        </a> */}
              <a style={{ cursor: 'pointer' }} className="dropdown-item notify-item" onClick={logoff}>
                <i className="mdi mdi-logout me-1"></i>
                <span>Logout</span>
              </a>
              {!isMobile && (
                <a
                  style={{ cursor: 'pointer' }}
                  className="dropdown-item notify-item"
                  onClick={() => navigate("menu/menuAdministrativo")}
                  hidden={viewBtnAdm}
                >
                  <span className="iconify me-1" data-icon="clarity:administrator-solid" data-width="16"></span>
                  <span>Administrativo</span>
                </a>
              )}
            </div>
          </li>
        </div>
      </nav>


      <ModalLoading show={loading} label="Carregando dados" />

      <ModalSelecionarFilialLink
        abrir={abrir}
        onClose={fecharModal}
        onclick={receberDados}
      />
    </>
  );
};
export default NavBar;
